import { useEffect, useState } from "react"
import ModalWrapper from "../Modal/ModalWrapper"
import Checkbox from "../common/form-controll/Checkbox/Checkbox"
import { Dialog } from "@headlessui/react"
import Typography from "../typography"

import Image from "next/image"
import { cx } from "class-variance-authority"
import { Controller, useForm } from "react-hook-form"
import Button from "../common/Button/Button"
import { IConsent, Consent, ConsentProduct, IOffer, IProduct } from "src/types/consentType"

import useConsents from "src/server/queryHooks/useConsents"

import Cookies from "js-cookie"

interface Props {
  setStep: (step: string) => void
  consentData: IConsent
  consent: any
  setConsent: any
}

const ModifyTnetProducts = ({ setStep, consentData, consent, setConsent }: Props) => {
  const { control, watch, setValue } = useForm()

  const { userPostConsentMutation } = useConsents()

  const TNETProductsOffers: any = consentData.offers[0].products

  useEffect(() => {
    const initialOffers = consentData.offers.map(item => ({
      scope: item.scope,
      priority: item.priority,
      products: item.products.map(product => ({
        uuid: product.uuid,
        accept: false
      }))
    }))

    setConsent({ offers: initialOffers })
  }, [])

  const [isOpen, setIsOpen] = useState(true)

  const productCheckboxes = consent.offers.flatMap((item: IOffer) => item.products.map((product: IProduct) => `product-${product.uuid}`))

  const allCheckboxStates = watch(productCheckboxes)

  const allChecked = allCheckboxStates.every((checked: boolean) => checked === true)

  useEffect(() => {
    setValue("product-all", allChecked)
  }, [allCheckboxStates, allChecked, setValue])

  const handleProductChange = (uuid: number, isChecked: boolean) => {
    watch(productCheckboxes).filter((checked: boolean) => checked).length

    const updatedConsent = {
      offers: consent.offers.map((offer: Consent) => ({
        ...offer,
        products: offer.products.map((product: ConsentProduct) => (product.uuid === uuid ? { ...product, accept: isChecked } : product))
      }))
    }

    setConsent(updatedConsent)
  }

  const handleSelectAllChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked

    productCheckboxes.forEach((uuid: number) => {
      setValue(uuid.toString(), isChecked)
    })

    setValue("all-partners", isChecked)

    const updatedConsent = {
      offers: consent.offers.map((offer: IOffer) => ({
        ...offer,
        products: offer.products.map((product: IProduct) => ({
          ...product,
          accept: isChecked
        }))
      }))
    }

    setConsent(updatedConsent)
  }

  const handleSelectAllPartners = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked

    const updatedConsent = {
      offers: consent.offers.map((item: Consent, index: number) => ({
        ...item,
        products:
          index === 1
            ? item.products.map((product: ConsentProduct) => ({
                uuid: product.uuid,
                accept: isChecked
              }))
            : item.products
      }))
    }

    setConsent(updatedConsent)
  }

  const handleAgreement = () => {
    if (!userPostConsentMutation.isLoading) {
      userPostConsentMutation.mutate(consent)
      setIsOpen(false)

      const consentResponseTime = new Date().toUTCString()

      sessionStorage.setItem("consentTime", consentResponseTime)
    }
  }

  return (
    <ModalWrapper
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      width="664px"
      height="md:h-570px"
      className="z-100 h-[600px] justify-start bg-white p-4 pt-0 text-start md:h-auto md:px-8 md:pt-6">
      <Dialog.Title className={`sticky top-0 z-10 flex gap-4 bg-white py-4 md:pt-0`}>
        <Image
          src="/images/arrow_back_ios.svg"
          width={12}
          height={20}
          alt="arrow"
          onClick={() => setStep("Basic")}
          className="cursor-pointer"
        />
        <Typography type="h5" weight="medium">
          {consentData.offers[0].title}
        </Typography>
      </Dialog.Title>
      <div className="custom-scroll flex w-full flex-1 flex-col items-center gap-4 overflow-scroll pb-4 md:flex-row md:flex-wrap">
        <div
          key={"All Products"}
          className="flex h-14 w-full items-center justify-between rounded-xl border border-slate-300 md:w-[calc(50%-0.5rem)]">
          <Typography type="text" weight="medium" className="p-4">
            ყველას მონიშვნა
          </Typography>
          <Controller
            name={`product-all`}
            control={control}
            defaultValue={false}
            render={({ field }) => (
              <Checkbox
                className="mr-4 w-5"
                selected={allChecked}
                label=""
                {...field}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  field.onChange(e)
                  handleSelectAllChange(e)
                }}
              />
            )}
          />
        </div>
        {TNETProductsOffers.map((product: any) => (
          <div
            key={product.uuid}
            className="flex h-14 w-full items-center justify-between rounded-xl border border-slate-300 md:w-[calc(50%-0.5rem)]" // Set a fixed height (adjust as needed)
          >
            <Image
              src={product.imageURL}
              width={96}
              height={24}
              alt={product.title}
              className="h-full p-4"
              style={{ objectFit: "contain" }}
            />
            <Controller
              name={`product-${product.uuid}`}
              control={control}
              defaultValue={false}
              render={({ field }) => (
                <Checkbox
                  className="mr-4 w-5"
                  selected={field.value}
                  label=""
                  {...field}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    field.onChange(e.target.checked)
                    handleProductChange(product.uuid, e.target.checked)
                  }}
                />
              )}
            />
          </div>
        ))}
        <div className="flex w-full items-center justify-between rounded-xl border border-slate-300 md:w-[calc(50%-0.5rem)]">
          <div className="mb-1 flex flex-col gap-2 px-4">
            <Typography type="text" weight="medium" className="">
              პარტნიორი კომპანიები
            </Typography>
            <Typography type="small" className="cursor-pointer text-primary-100 underline" onClick={() => setStep("PartnerCompaniesModal")}>
              პროდუქტების ჩამონათვალი
            </Typography>
          </div>
          <Controller
            name="all-partners"
            control={control}
            defaultValue={false}
            render={({ field }) => (
              <Checkbox
                className="mr-4 w-5"
                selected={allChecked ? true : field.value}
                label=""
                {...field}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  field.onChange(e)
                  handleSelectAllPartners(e)
                }}
              />
            )}
          />
        </div>
      </div>

      <div className="absolute sticky bottom-0 flex w-full justify-end bg-white pt-4 md:p-0">
        <Button text="ვეთანხმები" intent={"primary"} className="w-full px-10 py-1 md:w-auto" onClick={handleAgreement} />
      </div>
    </ModalWrapper>
  )
}

export default ModifyTnetProducts
