import { QueryClient, dehydrate } from "@tanstack/react-query"
import dynamic from "next/dynamic"
import { useEffect, useState } from "react"
import ResumeApplyProvider from "src/context/ResumeApplyProvider"
import { QUERY_KEYS } from "src/server/queryHooks/queryKeys"
import SearchBox from "src/views/pages/index/SearchBox/SearchBox"
import { SearchProvider } from "src/views/pages/index/SearchBoxNew/hooks/SearchContext"

import ConsentExternalModal from "src/views/components/ConsentsExternalModal/ConsentExternalModal"
import ConsentModal from "src/views/components/ConsentsModals/ConsentModal"
import ShowAgainConsentModal from "src/views/components/ConsentsModals/ShowAgainConsentModal"

import useUser from "src/hooks/useUser"
import useConsents from "src/server/queryHooks/useConsents"
import { checkConsentTime } from "src/utils/helpers/consents/checkConsentTime/checkConsentTime"

import CompleteProfileCard from "src/views/components/CompleteProfileCard/CompleteProfileCard"

import { serverSideTranslations } from "next-i18next/serverSideTranslations"

import { useTranslation } from "react-i18next"

import { getActiveCateogires } from "src/query-library/queries/useActiveCategoriesQuery"
import PageMeta from "src/views/pages/main/PageMeta"

const IndexLayout = dynamic(() => import("src/views/layouts/IndexLayout"), { ssr: true })
const CategoryCardsContainer = dynamic(() => import("src/views/pages/index/CategoryCardsContainer"), { ssr: false })
const LandingGenerator = dynamic(() => import("src/views/pages/index/helpers/landingGenerator"), { ssr: false })
const LandingBanner = dynamic(() => import("src/views/pages/index/LandingBanner"), { ssr: false })

const Index = () => {
  const { userConsentsQuery } = useConsents()
  const { userExternalConsentsQuery } = useConsents()

  const { connectedUser } = useUser()

  const [showModal, setShowModal] = useState<boolean | string>(false)

  const { t, i18n } = useTranslation("common")

  const pageMeta = {
    title: t("meta_text.title"),
    desc: t("meta_text.desc"),
    keywords: t("meta_text.keywords"),
    img: "/images/myjobs_logo.svg",
    canonical: "https://wwww.myjobs.ge"
  }

  useEffect(() => {}, [i18n.language])

  useEffect(() => {
    const savedTime = sessionStorage.getItem("consentTime")

    if (!connectedUser && savedTime) {
      sessionStorage.removeItem("consentTime")
    }
  }, [connectedUser])

  useEffect(() => {
    if (userConsentsQuery.isSuccess && userConsentsQuery.data) {
      if (!userConsentsQuery.data.expanded) {
        setShowModal("ModifyShowAgainModal")
      }

      if (userConsentsQuery.data.expanded) {
        setShowModal("ConsentGeneralModal")
      }
    }
  }, [userConsentsQuery.isSuccess, userConsentsQuery.data])

  useEffect(() => {
    const interval = setInterval(() => {
      const consentTime = checkConsentTime()

      if (consentTime) {
        setShowModal("ExternalProductsConsentModal")
        sessionStorage.removeItem("consentTime")
      }
    }, 10000)

    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    const savedTime = sessionStorage.getItem("consentTime")

    if (!userConsentsQuery.data && !savedTime && userExternalConsentsQuery.isSuccess && userExternalConsentsQuery.data) {
      setShowModal("ExternalProductsConsentModal")
    }
  }, [userExternalConsentsQuery.isSuccess, userExternalConsentsQuery.data])

  const handleCloseModal = () => {
    setShowModal(false)
  }

  return (
    <>
      <PageMeta meta={pageMeta} />
      {userConsentsQuery.data && showModal === "ConsentGeneralModal" && (
        <ConsentModal isOpen={true} onClose={handleCloseModal} consentData={userConsentsQuery.data} />
      )}

      {userConsentsQuery.data && showModal === "ModifyShowAgainModal" && <ShowAgainConsentModal consentData={userConsentsQuery.data} />}

      {userExternalConsentsQuery.data && showModal === "ExternalProductsConsentModal" && (
        <ConsentExternalModal isOpen={true} onClose={handleCloseModal} consentData={userExternalConsentsQuery.data} />
      )}

      {/* Apply to vacancy modal Modal */}
      <IndexLayout>
        <div className="mx-4 mt-6 flex max-w-[1030px] flex-col items-center gap-3 pb-[40px] md:mx-auto md:mt-[100px] md:gap-7 md:pb-[80px]">
          <SearchProvider>
            <SearchBox />
          </SearchProvider>
        </div>
        {connectedUser && <CompleteProfileCard />}
        <LandingBanner />
        {/* <RecentlySearchedButton className="no-scrollbar ml-4 w-11/12 md:ml-8" /> */}

        {/* Categories with vacacncy count */}
        <CategoryCardsContainer />

        {/* MAIN DATA IS IN LANDING GENERATOR */}
        <ResumeApplyProvider>
          <LandingGenerator />
        </ResumeApplyProvider>
      </IndexLayout>
    </>
  )
}

// queryClient.prefetchQuery([QUERY_KEYS.TOP_COMPANIES], () => fetchTopCompanies(ctx.req)),
const queryClient = new QueryClient()

export async function getServerSideProps(ctx: any) {
  await Promise.all([queryClient.prefetchQuery([QUERY_KEYS.ACTIVE_CATEGORIES], () => getActiveCateogires(ctx.req))])

  return {
    props: {
      dehydratedState: dehydrate(queryClient),
      ...(await serverSideTranslations(ctx.locale || "ka", ["common"]))
    }
  }
}

export default Index
