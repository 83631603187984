import { useState } from "react"
import ModalWrapper from "../Modal/ModalWrapper"
import Typography from "../typography"
import { Dialog } from "@headlessui/react"
import Button from "../common/Button/Button"
import { IConsent, IProduct, IOffer, ISendUserConsent } from "src/types/consentType"
import { ReplaceTextWithLinks } from "src/utils/helpers/consents/ReplaceTextWithLinks"
import useConsents from "src/server/queryHooks/useConsents"
import ModifyExternalProducts from "./ModifyExternalProducts"

interface Props {
  isOpen: boolean
  onClose: () => void
  className?: string
  height?: string
  width?: string
  consentData: IConsent
}

interface IExternalProducts {
  uuid: number
  accept: false
}

interface IExternalConsents {
  offers: IExternalProducts[]
}

const ConsentExternalModal = ({ isOpen, onClose, className, consentData }: Props) => {
  const { userExternalPostConsentMutation } = useConsents()

  const externalData = consentData.offers[0].products

  const initialOffers = externalData
    ? externalData.map((product: any) => ({
        uuid: product.uuid,
        accept: false
      }))
    : {}

  const [consent, setConsent] = useState<any>({ offers: initialOffers })

  const [step, setStep] = useState("Basic")

  const handleModify = () => setStep("ModifyExternalProducts")

  const handleRejectAll = () => {
    if (Object.keys(consent).length > 0 && !userExternalPostConsentMutation.isLoading) {
      const rejectAll: IExternalConsents = {
        offers: consent?.offers.map((product: IExternalProducts) => ({
          uuid: product.uuid,
          accept: false
        }))
      }

      userExternalPostConsentMutation.mutate(rejectAll)
      onClose()
    }
  }

  const handleSubmit = () => {
    if (Object.keys(consent).length > 0 && !userExternalPostConsentMutation.isLoading) {
      const acceptAll: IExternalConsents = {
        offers: consent?.offers.map((product: IExternalProducts) => ({
          uuid: product.uuid,
          accept: true
        }))
      }

      userExternalPostConsentMutation.mutate(acceptAll)
      onClose()
    }
  }

  return (
    <>
      {step === "Basic" && (
        <ModalWrapper
          isOpen={isOpen}
          onClose={onClose}
          width="664px"
          height="md:280px"
          className={`justify-start p-6 text-start md:px-8 md:py-6  ${className}`}>
          <Dialog.Title className="sticky top-0 z-10 flex bg-white pb-4">
            <Typography type="h5" weight="bold">
              {consentData.title}
            </Typography>
          </Dialog.Title>
          <div className="custom-scroll mb-4 flex flex-col gap-4 overflow-y-auto md:mb-5">
            {consentData.document.map((text: string, index: number) => (
              <Typography type="text" key={text}>
                {ReplaceTextWithLinks(consentData.agreements, text)}
              </Typography>
            ))}
          </div>
          <div className="sticky bottom-0 z-10 flex flex-col flex-col-reverse justify-end bg-white sm:flex-row sm:gap-5">
            <div className="flex gap-3">
              <Button
                text="არ ვეთანხმები"
                intent="ghost"
                weight="medium"
                className="w-full px-0 text-secondary-50 hover:bg-transparent sm:w-auto"
                onClick={handleRejectAll}
              />
              <Button
                text="მოდიფიკაცია"
                intent="ghost"
                weight="medium"
                onClick={handleModify}
                className="w-full px-0 text-secondary-50 hover:bg-transparent sm:w-auto"
              />
            </div>
            <div>
              <Button text="ვეთანხმები" intent="primary" className="w-full cursor-pointer px-10 py-1" onClick={handleSubmit} />
            </div>
          </div>
        </ModalWrapper>
      )}
      {step === "ModifyExternalProducts" && (
        <ModifyExternalProducts setStep={setStep} consentData={consentData} consent={consent} setConsent={setConsent} />
      )}
    </>
  )
}

export default ConsentExternalModal
