import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { ACCESS_TOKEN_NAME } from "src/env"
import RegisterService from "src/server/services/RegisterService"
import { INaturalPersonPayload } from "src/types/naturalPersonWizardTypes"
import { CacheTime } from "src/utils/configs/apiCacheTime"
import Cookie from "src/utils/configs/Cookie"
import { INaturalPersonInterface } from "../../types/naturalPersonWizardTypes"
import UserService from "../services/UserService"
import { QUERY_KEYS } from "./queryKeys"
import moment from "moment"

const usePersonApi = () => {
  const queryClient = useQueryClient()

  const naturalPersonQuery = useQuery<INaturalPersonInterface | null>({
    queryKey: [QUERY_KEYS.GET_NATURAL_PERSON_DATA],
    queryFn: () => fetchNaturalPerson(),
    staleTime: CacheTime.MAIN_PAGE,
    cacheTime: CacheTime.MAIN_PAGE,
    enabled: !!Cookie.get(ACCESS_TOKEN_NAME)
  })

  const naturalPersonMutation = useMutation({
    mutationFn: (data: INaturalPersonPayload) => {
      // YYYY-MM-DD
      data.birth_date = moment(data.birth_date).format("YYYY-MM-DD")

      return putNaturalPerson(data)
    },
    onSuccess: response => {
      queryClient.setQueryData([QUERY_KEYS.GET_NATURAL_PERSON_DATA], response.data)
    }
  })

  const fetchNaturalPersonMutation = useMutation({
    mutationFn: () => naturalPersonQuery.refetch()
  })

  const imageNaturalPersonMutation = useMutation({
    mutationFn: (data: { path: string }) => postImageNaturalPerson(data),
    onSuccess: () => {
      queryClient.setQueryData([QUERY_KEYS.GET_NATURAL_PERSON_DATA], (prev: any) => {
        if (!prev) return null

        return {
          ...prev,
          photo_version: prev.photo_version + 1
        }
      })
    }
  })

  const avatarMutation = useMutation({
    mutationFn: (data: { path: string }) => updateAvatar(data)
  })

  const uploadResumeMutation = useMutation({
    mutationFn: (data: any) => uploadResume(data)
  })

  const deleteResumeMutation = useMutation({
    mutationFn: () => deleteResume()
  })

  const downloadResumeQuery = useQuery({
    queryKey: [QUERY_KEYS.GET_CV, naturalPersonQuery.data?.user_id],
    queryFn: () => downloadResume(naturalPersonQuery.data?.cv?.id),
    enabled: false
  })

  const languageQuery = useQuery({
    queryKey: [QUERY_KEYS.GET_LANGUAGE],
    queryFn: () => getLanguage(),
    enabled: !!Cookie.get(ACCESS_TOKEN_NAME)
  })

  const setLanguageMutation = useMutation({
    mutationFn: (data: any) => setLanguage(data)
  })

  return {
    languageQuery,
    naturalPersonQuery,
    naturalPersonData: naturalPersonQuery.data,
    imageNaturalPersonMutation,
    avatarMutation,
    naturalPersonMutation,
    downloadResumeQuery,
    uploadResumeMutation,
    deleteResumeMutation,
    fetchNaturalPersonMutation,
    setLanguageMutation
  }
}

export const fetchNaturalPerson = async (req?: any) => {
  try {
    const response: any = await UserService.getNaturalPerson(req)

    if (response.data.message === "not registered") {
      return null
    }

    const registrationData = response.data.data

    return registrationData
  } catch (error: any) {
    throw new Error("Failed to fetch fetchNaturalPerson", error)
  }
}

export interface AttributeErrors {
  attribute: string
  message: string
}
export const putNaturalPerson = async (data: INaturalPersonPayload) => {
  try {
    const response: any = await RegisterService.putPersonRegisterFinalFormData(data)

    return response.data
  } catch (error: any) {
    if (error.response) {
      throw error.response.data.errors || "სერვერის შეცდომა"
    } else {
      throw "ქსელის შეცდომა"
    }
  }
}

export const downloadResume = async (cv_id: number | undefined) => {
  try {
    const response: any = await UserService.getResume(cv_id)
    const url = URL.createObjectURL(response.data) // Create a URL for the Blob
    window.open(url, "_blank") // Open the Blob URL in a new tab
    return response.data
  } catch (error: any) {
    throw new Error("Failed to downloadResume", error)
  }
}

export const uploadResume = async (data: any) => {
  try {
    const response: any = await UserService.uploadResume(data)

    return response.data
  } catch (error: any) {
    throw new Error("Failed to post uploadResume", error)
  }
}

export const deleteResume = async () => {
  try {
    const response: any = await UserService.deleteResume()

    return response.data
  } catch (error: any) {
    throw new Error("Failed to delete deleteResume", error)
  }
}

export const getLanguage = async () => {
  try {
    const response: any = await UserService.getActiveLanguage()

    return response.data
  } catch (error: any) {}
}

export const setLanguage = async (data: any) => {
  try {
    const response: any = await UserService.setActiveLanguage(data)

    return response.data
  } catch (error: any) {}
}

export const postImageNaturalPerson = async (data: { path: string }) => {
  try {
    const response: any = await RegisterService.postImageNaturalPerson(data)

    return response.data
  } catch (error: any) {
    if (error.response) {
      throw error.response.data.errors || "სერვერის შეცდომა"
    } else {
      throw "ქსელის შეცდომა"
    }
  }
}

export const updateAvatar = async (data: { path: string }) => {
  try {
    const response: any = await RegisterService.postAvatar(data)

    return response.data
  } catch (error: any) {}
}

export default usePersonApi
